<template>
    <Modal
        ref="confirmModal"
        :title="title"
        class="confirm_modal"
        :class="{'isModalConfirm':!isSequenceModalOpen}"
        name="confirmation"
        :showHeader="showHeader"
        :submitButtonText="buttonText"
        @submit="submitModal"
        @closed="cancelModal"
        width="30%"
        :loading="loading"
        v-bind="$attrs"
    >
        <template #content v-if="message">
            <slot name="content">{{ message }}</slot>
        </template>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </Modal>
</template>

<script>
/* 
    Use for getting confirmation result.
    Example:
    const confirm = await this.$refs['modal-confirm'].show(config);
    config includes params: title, message, autoclose.
    Use autoclose: true to close 
*/
import Modal from "@shared/modal-content";

export default {
    name: "modal-confirm",
    components: {
       Modal,
    },
    data() {
        return {
            title: "Confirmation",
            message: "",
            buttonText: 'Confirm',
            loading: false,
            autoclose: true,
            resolvePromise: undefined,
            rejectPromise: undefined,
        };
    },
    props: {
        showHeader:{
            type:Boolean,
            default: true
        },
        isSequenceModalOpen: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    methods: {
        show(opts = {}) {
            this.title = opts.title;
            this.message = opts.message;
            this.buttonText = opts.buttonText;
            this.autoclose = opts.autoclose ?? true;
            this.loading = false;
            this.$refs.confirmModal.showModal();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        submitModal() {
            this.resolvePromise(true);
            if (this.autoclose) this.$refs.confirmModal.hideModal();
            else this.loading = true;
        },
        cancelModal() {
            this.loading = false;
            this.resolvePromise(false);
            // if (this.autoclose) this.$refs.confirmModal.hideModal();
        },
        close() {
            // this.loading = false;
            this.$refs.confirmModal.hideModal();
        },
    },
};
</script>

<style lang="scss" scoped>
    .confirm_modal{
        z-index: 9999 !important;
    }
    .isModalConfirm{
        z-index: 9 !important;
    }
</style>
